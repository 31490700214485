<template>
    <div class="modalFilter">
        <div class="content">
            <span v-html="$translate(title)" />
        </div>
        <div class="hr" />
        <!-- <span>성별</span> -->
        <div class="filter-gender-container">
            <div
                @click="selectFilter(filter, idx)"
                class="filter filter-gender"
                :class="{ active: idxList.includes(idx) ? true : false }"
                v-html="filter.title"
                :key="idx"
                v-for="(filter, idx) in filterTypes"
            ></div>
        </div>
        <!-- <div class="hr" />
        <span>게시글 주제</span>
        <div class="filter-category-container">
            <div
                @click="selectFilter(filter, idx + 2)"
                class="filter filter-category"
                :class="{ active: idxList.includes(idx + 2) ? true : false}"
                v-html="filter.title"
                :key="idx"
                v-for="(filter, idx) in filterTypes.slice(2)"
            >
            </div>
        </div> -->
        <div class="filter-button">
            <button v-html="$translate('CONFIRM')" @click="onClose" class="btn-primary" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalFilter',
    props: ['options'],
    data: () => ({
        idxList: [],
    }),
    computed: {
        title() {
            return (this.options || {}).title
        },
        filterType() {
            return (this.options || {}).filterType
        },
        filterTypes() {
            return (this.options || {}).filterTypes
        },
    },
    methods: {
        async init() {
            try {
                this.$loading(true)
                await this.setIdxList()
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        setIdxList() {
            if (this.filterType) {
                this.idxList = this.filterType[0]
            } else {
                this.idxList.push(0)
            }
        },
        selectFilter(filter, idx) {
            if (this.idxList.includes(idx)) {
                this.idxList.splice(this.idxList.indexOf(idx), 1)
                this.idxList.push(0)
            } else if (idx < 3) {
                if (idx === 1 && this.idxList.includes(2)) {
                    this.idxList.splice(this.idxList.indexOf(2), 1)
                    this.idxList.splice(this.idxList.indexOf(0), 1)
                    this.idxList.push(idx)
                } else if (idx === 2 && this.idxList.includes(1)) {
                    this.idxList.splice(this.idxList.indexOf(1), 1)
                    this.idxList.splice(this.idxList.indexOf(0), 1)
                    this.idxList.push(idx)
                } else {
                    this.idxList.splice(this.idxList.indexOf(1), 1)
                    this.idxList.splice(this.idxList.indexOf(2), 1)
                    this.idxList.push(idx)
                }
                // } else if (idx === 2) {
                //     this.idxList.push(idx)
                //     for (var i = 3; i < 8; i++) {
                //         if (this.idxList.includes(i)) {
                //             this.idxList.splice(this.idxList.indexOf(i), 1)
                //         }
                //     }
                // } else if (idx > 2) {
                //     if (this.idxList.includes(2)) {
                //         this.idxList.splice(this.idxList.indexOf(2), 1)
                //     }
                //     this.idxList.push(idx)
            }
        },

        onClose() {
            this.$emit('close', this.idxList)
        },
    },
    created() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.modalFilter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    // height: 208px;
    border-radius: 12px 12px 0 0;
    padding: 0;

    .content {
        padding: 24px 24px 16px 24px;
        color: black;
        text-align: center;
        font-size: 16px;

        @include f-medium;

        // button {
        //     padding: 5px;
        //     float: right;
        //     border-radius: 8px;
        //     font-weight: 100;
        // }
    }

    .hr {
        width: 100%;
        height: 1px;
        background: $grey-02;
        margin-top: 8px;
        margin-bottom: 12px;
    }

    .filter-gender-container {
        // padding: 0px 24px 40px 24px;
        flex-direction: row;
        // flex-wrap: wrap;
        display: flex;
        .filter {
            margin: 20px auto 30px;
            font-size: 16px;
            padding: 0 14px;
            color: black;
            border: 1px solid $purple-primary;
            border-radius: 10px;
            @include f-regular;

            &.active {
                background-color: $purple-primary;
                color: white;
                @include f-medium;
            }
        }
    }

    .filter-category-container {
        padding: 0px 24px 40px 24px;
        flex-direction: row;
        flex-wrap: wrap;
        display: flex;
        .filter {
            margin: 28px 10px;
            font-size: 16px;
            padding: 0 3px;
            color: black;
            border: 2px solid $purple-primary;
            border-radius: 10px;
            @include f-regular;
            @include center;

            &.active {
                background-color: $purple-primary;
                color: white;
                @include f-medium;
            }
        }
    }

    .filter-button {
        button {
            font-size: 16px;
            width: 90%;
            margin: 5px 0 5px 5%;
            padding: 10px;
            border-radius: 10px;
        }
    }
}
</style>
